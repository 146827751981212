import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from "react-slick";

import Loading from './Components/_Loading';

import Wtbl from './Components/Wtbl';
import WtblListView from './Components/WtblListView';
import ReviewsPreview from './Components/ReviewsPreview';
import Reviews from './Components/Reviews';
import Id from './Id';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Product extends React.Component {  
    constructor(props) {
        super(props);
     
        this.state = {
            hasError: false,
            isLoaded: false,
            item: [],
            content:'', 
            organisation_id: this.props.match.params.organisation_id,
            account_id: this.props.match.params.account_id,
            product_id: this.props.match.params.product_id
        };
    }
    componentWillMount() {
        const currentProductId = this.props.match.params.product_id
        
        this.setState({
            product_id: currentProductId,
        });

        if(this.props.match.params.option == 'wtbl'){

            this.setState({
                isLoaded: true
            });
        }
        else {
            //console.log(currentProductId);
            //this.fetchApi(currentProductId);
        }
        this.fetchApi(currentProductId);
        
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.match.params.product_id !== this.props.match.params.product_id) {
        const newProductId = nextProps.match.params.product_id
        
        //console.log(newProductId);
        this.setState({
            isLoaded: false,
            product_id: newProductId,
        });
        this.fetchApi(newProductId);
      }
    }
    fetchApi(productId){
      let url =  process.env.REACT_APP_API_URL+'/getProduct/'+productId+'?account_id='+this.state.account_id;
      /*const api_data = {
        "type_company": localStorage.getItem("type_company") || ''
      }*/
  
      fetch(url, {
        method: 'GET',
        headers: {
            //"Authorization": tkn,
            "Content-Type": "application/json", 
        }, //no body > uid_user is stored in token
        //body: JSON.stringify(api_data)
      })
      .then((res) => res.json())
      .then((result) => { 
            if(result){
                console.log(result);
                //console.log(result.product_images);
                this.setState({
                    isLoaded: true,
                    hasError: false,
                    item: result
                });
            }
            else {
                this.setState({
                    isLoaded: true,
                    hasError: true
                });
            }
        },
        (error) => {
            console.log(error.toString());
            this.setState({
              isLoaded: true,
              hasError:true
            });
        }
      )
    } 
    //NOTIFICATION
    subscribeNoti(){
        alert('Coming soon');
    }
    renderNotiBtn(){
        return (
            <button className="btn btn-secondary btn-sm align-self-center" onClick={this.subscribeNoti}>Get notified</button>
        )
    }

    //RENDER
    renderPage(param){
        switch(param) {
            case 'wtbl':
                return this.renderWtbl();
            case 'wtbllistview':
                return this.renderWtblListView();
            case 'reviews':
                return this.renderReviews();
            case 'id':
                return this.renderId();
            default:
                return this.renderProduct();
          }
    }
    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderPage(this.props.match.params.option)) : (       
            <Loading />
        );            
    } 
    renderError() {  
        return (       
            <span>Oops ! Somethign went wrong, try to hit the back button or report it to help@gethatch.com</span>
        );            
    } 
    renderStock(param){
        switch(param) {
            case '0':
              return 'Out of stock';
            case '-99':
                return 'Pre-order';
            default:
              return 'In stock';
          }
    }
    renderCta(param,link){
        switch(param) {
            case '0':
              return this.renderNotiBtn()
            case '-99':
                return this.renderNotiBtn()
            default:
              return <a href={link} className="align-self-center color-black" target='_blank'><i className="gg-chevron-right"></i></a>
          }
    }
    renderProduct(){
        //change meta
        document.title = this.state.item.product_name;
        //Slider config
        var settings = {
            arrows: false,
            adaptiveHeight: false,
            dots: true,
            infinite: false,
            draggable: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
        
            <div>
                
                <Link
                className="header_btns backLink btn btn-primary"
                to={"/o/"+this.state.organisation_id+"/a/"+this.state.account_id}>
                    <i className="gg-chevron-left"></i>
                </Link>

                <Slider {...settings} className="mb-3">
                {this.state.item.product_images.map((item,key) => {
                    return (
                        
                        <div key={"img_"+key} id={"img_"+key}>
                            <img src={item} />
                        </div>
                        )
                        
                })}
                </Slider>

                <div  className="container">
                    <h5 className="mb-0">{this.state.item.product_name}</h5>

                    <ReviewsPreview organisation_id={this.state.organisation_id} product_id={this.state.product_id} product_mpn={this.state.item.product_mpn} />                        

                    <p>{this.state.item.product_description}</p> 
                    <hr />
                    <div className="mb-3">
                        <Link to={window.location.pathname+"/wtbl"} className="wtb_local d-flex color-black">
                            <img src="/cdn/img/delivery-time-symbol.svg" className="align-self-center" />
                            <span className="align-self-center">Find this product near you</span>
                            <i className="ml-auto gg-chevron-right align-self-center"></i>
                        </Link>
                    </div>
                    <hr />
                    <div className="mb-3">
                        <Link to={window.location.pathname+"/id"} className="wtb_id d-flex color-black">
                            <img src="/cdn/img/delivery-truck-with-circular-clock.svg" className="align-self-center" />
                            <span className="align-self-center">30 mins delivery</span>
                            <i className="ml-auto gg-chevron-right align-self-center"></i>
                        </Link>
                    </div>
                    <hr />
                    <div className="mb-3">Starting at <b>{this.state.item.lowest_price.offer_price} {this.state.item.lowest_price.offer_currency}</b></div>
                    <div className="wtb_offers">
                        {this.state.item.offers.map((item,key) => {
                        return (
                                <div className="d-flex justify-content-between  mb-3">
                                    <a href={item.offer_link} target='_blank' className="wtb_offer d-flex justify-content-start color-black">
                                        <div className="wtb_offer_img d-flex justify-content-center"><img src={item.merchant_logo} className="align-self-center" /></div>
                                        <div className="wtb_offer_price_stock flex-grow-1 align-self-center">
                                            <b>{item.offer_price} {item.offer_currency}</b>
                                            <br />
                                            {this.renderStock(item.offer_stock)}
                                        </div>
                                    </a>
                                    <div className="d-flex">{this.renderCta(item.offer_stock,item.offer_link)}</div>
                                </div>
                                
                            )
                            
                    })}
                    </div>
                    <div className="mb-5">&nbsp;</div>
                </div>
            </div>
        )
    }
    renderWtbl(){  
        return (   
            <Wtbl organisation_id={this.state.organisation_id} product_id={this.state.product_id} product_mpn={this.state.item.product_mpn} product_name={this.state.item.product_name} />
        );            
    }
    renderWtblListView(){  
        return (   
            <WtblListView organisation_id={this.state.organisation_id} product_id={this.state.product_id} product_mpn={this.state.item.product_mpn} product_name={this.state.item.product_name} />
        );            
    }
    renderReviews(){  
        return (   
            <Reviews organisation_id={this.state.organisation_id} product_id={this.state.product_id} product_mpn={this.state.item.product_mpn} product_name={this.state.item.product_name} />
        );             
    } 
    renderId(){  
        return (   
            <Id 
                organisation_id={this.state.organisation_id} 
                product_id={this.state.product_id} 
                product_mpn={this.state.item.product_mpn} 
                product_name={this.state.item.product_name} 
                product_image={this.state.item.product_images[0]} 
            />
        );             
    } 
    
}

export default Product;