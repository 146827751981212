import React, { Component } from 'react';

class WtblItem extends Component {
  renderDistance(distance){
      if(distance < 1000) var d = Math.trunc(distance)+' m';
      else var d = ((distance/1000).toFixed(2))+' km';
      return <span>{d}</span>
  }
  render(){
    return (
      <div className="row wtbl_item mt-1">
        <div className="col-12">
            <span className="location_name">{this.props.loc_data.retailer.name}</span>
            <span className="retailer_name">{this.props.loc_data.name}</span>
            <span className="location_address">{this.props.loc_data.address.street}, {this.props.loc_data.address.zip} - {this.props.loc_data.locality}</span>
            <br />
            <div className="location_distance">Distance : {this.renderDistance(this.props.loc_data.distance)}</div>
        </div>
    </div>

  )
  }
 };
 
 export default WtblItem;