import React from 'react';
import { Link } from 'react-router-dom';

class Account extends React.Component {  

    constructor(props) {
        super(props);
     
        this.state = {
            hasError: false,
            isLoaded: false,
            items: [],
            organisation_id: this.props.match.params.organisation_id,
            account_id: this.props.match.params.account_id,
        };
    }
    componentWillMount() {
        const currentAccountId = this.props.match.params.account_id
        this.setState({account_id: currentAccountId});
        this.fetchApi(currentAccountId);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.account_id !== this.props.match.params.account_id) {
          const newAccountId = nextProps.match.params.account_id
          this.setState({
              isLoaded: false,
              account_id: newAccountId,
          });
          this.fetchApi(newAccountId);
        }
    }
    fetchApi(accountId){
    let url =  process.env.REACT_APP_API_URL+'/listProducts/'+accountId;
    fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json", 
        }, //no body > uid_user is stored in token
        //body: JSON.stringify(api_data)
    })
    .then((res) => res.json())
    .then((result) => { 
            if(result){
                console.log(result);
                //console.log(result.product_images);
                this.setState({
                    isLoaded: true,
                    hasError: false,
                    items: Object.values(result)
                });
            }
            else {
                this.setState({
                    isLoaded: true,
                    hasError: true
                });
            }
        },
        (error) => {
            console.log(error.toString());
            this.setState({
                isLoaded: true,
                hasError:true
            });
        }
    )}
    render() {  
        return (
            
        <div className="container">
            <header>
                <h2>Let's</h2>
                <h1>Get Started !</h1>
                <div className="favicon">

                </div>
            </header>
            <div className="wtb_products row">
                {this.state.items.map((item,key) => {
                return (
                        <div id={key} key={key} className="wtb_product col-6">
                            <div className="lowest_price">
                                Starts at {item.lowest_price}
                            </div>
                            <div className="wtb_product_inner">
                                <Link to={"/o/"+this.state.organisation_id+"/a/"+this.state.account_id+"/p/"+item.id_product}>
                                    <img src={item.product_image} />
                                    <span className="product_name">{item.product_name}</span>
                                </Link>
                            </div>
                        </div>
                        
                    )
                    
                })}
            </div>

        </div>
        )
    }  
}

export default Account;