import React from 'react';

import { Link } from 'react-router-dom';
import Backbutton from './Components/_BackButton';
import Spinner from './Components/_Spinner';
import IdStoreDetails from './Components/IdStoreDetails';

import { LoadScript, Autocomplete} from '@react-google-maps/api';


const libraries = ["places"]

class Id extends React.Component {  
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isLoaded: false,
            organisation_id: this.props.organisation_id,
            product_id: this.props.product_id,
            product_mpn: this.props.product_mpn,
            product_name: this.props.product_name,
            product_image: this.props.product_image,
            
            acConfig:{
              country: 'US'
            },
            user_location:{
                formatted_address:'',
                city:'',
                country_name:''
            },
            locations:[],
            wtbIdCtnr:'',
            containerCtnr:'',
            acCtnr:'',
            paiementCtnr:'',
            storepickerCtnr:'',
            selectedStoreCtnr:'',
            ordertrackingCtnr:'',
            wtbIdCtnrStatus:'',
            acCtnrStatus:'d-none',
            paiementCtnrStatus:'d-none',  
            ordertrackingCtnrStatus:'d-none',                 
            storepickerCtnrStatus:'flex-fill',                
            selectedStore:null,
            ret_stock_cnt:0,
            retailerInStoreData: []
        };

        document.title = 'Instant Delivery - '+this.props.product_name;

    }
    componentDidMount(){
        
        this.setState({wtbIdCtnr:document.getElementById('wtb_id')});
        this.setState({containerCtnr:document.querySelectorAll('#wtb_id .container')[0]});
        this.setState({acCtnr:document.getElementById('acCtnr')});
        this.setState({storepickerCtnr:document.getElementById('id_store_picker')});
        this.setState({paiementCtnr:document.getElementById('id_paiement')});
        this.setState({ordertrackingCtnr:document.getElementById('id_order_tracking')});
        
        let url =  'https://api.ipgeolocation.io/ipgeo?apiKey=2a23f67d1f204ecda84b9a451eabf9c0';
    
        fetch(url, {
          method: 'GET'
        })
        .then((res) => res.json())
        .then((result) => { 
            console.log(result);
            if(result){
                this.setState({
                    hasError: true,
                    user_location:{
                        formatted_address:result.city+', '+result.country_name
                    }
                });
                
                this.fetchApi({'lat':result.latitude,'lng':result.longitude});
            }
          },
          (error) => {
              console.log(error.toString());
          }
        )
    }
    onAcLoad = (autocomplete) => {  
      this.setState({
            autocomplete: autocomplete
        });
    }
    onPlaceChanged = () => {
        //reinitiate cards 
        
        this.setState({ selectedStoreCtnr: '' });
        this.setState({storepickerCtnrStatus:'flex-fill'});
        this.setState({paiementCtnrStatus:'d-none'});
        this.setState({ordertrackingCtnrStatus:'d-none'});

        if (this.state.autocomplete !== null) {
            
            this.setState({isLoaded: false,ret_stock_cnt:0});
            var new_place = this.state.autocomplete.getPlace();
            var new_loc = new_place.geometry.location;
            
            this.setState({
                user_location:{
                    formatted_address:new_place.formatted_address
                }
            });
            this.fetchApi({'lat':new_loc.lat(),'lng':new_loc.lng()});
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }
    fetchApi(loc){
        var query = {
            "countryCode": this.state.acConfig.country,
            "size" : 10,
            "geoCenterArea": {
                "center": {
                    "latitude": loc.lat,
                    "longitude": loc.lng
                },
                "distance": 20000
            }            
          };
          //Product
          query.product=this.state.product_mpn
          //Filters
          query.filters = [];
    
          let url =  "https://papi.gethatch.com/locations/"+this.state.organisation_id+"/geo/list";   
          fetch(url, {
            method: 'POST',
            headers: { 
                "Content-Type": "application/json", 
            },
            body: JSON.stringify(query)
          })
          .then((res) => res.json())
          .then((result) => {
                const location_check_cnt = Object.keys(result.locations).length;
                if(location_check_cnt > 0){
                    console.log(location_check_cnt);
                    this.setState({
                        //isLoaded: true,
                        retailerInStoreData:[], //reinitiate if location change
                        hasError:false,
                        locations: result.locations,
                        acCtnrStatus :'d-none'
                    });
                    
                    //fetchRetailerStock
                    result.locations.map((loc, index) => {
                        this.fetchRetailerStock(loc,(index+1),location_check_cnt).then(resp => {
                            console.log(resp);
                            if(resp == "true") this.setState({ret_stock_cnt:this.state.ret_stock_cnt+1});
                        });
                       
                        //console.log(this.state.retailerInStoreData);
                    });
                }
                else{
                    this.setState({
                        isLoaded: true,
                        hasError:true
                    });
                }
            },
            (error) => {
                console.log(error.toString());
                this.setState({
                  isLoaded: true,
                  hasError:true
                });
            }
          )
    }
    
    fetchRetailerStock(storeData,retCnt,retTot){
        let url =  process.env.REACT_APP_API_URL+'/retailerInStoreAvailability';
        var query = {
          "id_retailer" : storeData.retailer.id,
          "id_location" : storeData.searchableId,
          "location_data" : {
            "zip" : storeData.address.zip,
            "lat" : storeData.coordinates.latitude,
            "lng" : storeData.coordinates.longitude,
          } ,
          "product_id" : this.state.product_id
        };
        return fetch(url, {
          method: 'POST',
          headers: { 
              "Content-Type": "application/json", 
              "X-Requested-With": "XMLHttpRequest"
          },
          body: JSON.stringify(query)
        })
        .then((res) => res.json())
        .then((result) => { 
                console.log(result);
                if(result.success && result.response.inStore == "true"){
                    //this.state.retailerInStoreData[storeData.searchableId] =  result.response.inStore;
                    this.setState({
                        retailerInStoreData:{
                            ...this.state.retailerInStoreData,
                            [storeData.searchableId]: result.response.inStore
                        }
                    });
                    
                    //remove loading    
                    if(retCnt == retTot){
                        this.setState({ isLoaded: true});
                    }
                    return result.response.inStore;
                }
                else{
                    /*this.setState({
                    retailerInStoreData:{
                        ...this.state.retailerInStoreData,
                        [storeData.searchableId]: "false"
                    }
                    });*/    
                    //remove loading    
                    if(retCnt == retTot){
                        this.setState({ isLoaded: true});
                    }
                    return "false";        
                }
            },
            (error) => {
                console.log(error.toString());                
            }
          
        )
    }
    displayAc = () => {
        this.state.acCtnr.focus();
        //show input
        if(this.state.acCtnr.classList.contains('d-none')) this.setState({acCtnrStatus:''});
        else this.setState({acCtnrStatus:'d-none'});
    }
    selectStore = (index) => {
        //prepar prev bloc
        this.setState({ selectedStore: index });
        this.setState({ selectedStoreCtnr: 'selection_made' });
        //close cards
        this.setState({storepickerCtnrStatus:'reduced'});
        //open card #id_paiement
        this.setState({paiementCtnrStatus:'flex-fill'});       
        
    }
    placeOrder = (index) => {

        var pt = (this.state.containerCtnr.clientHeight-500)+"px"; //500 approx heigh of cards (need to cal but for demo it's ok)
        console.log(pt);
        this.state.containerCtnr.style.paddingTop = pt;
        //Prepare prev bloc
        this.setState({paiementCtnrStatus:'reduced'});  
        //close cards
        
        //open card #id_order_tracking
        
        this.setState({wtbIdCtnrStatus:'open_tracking'});
        this.setState({ordertrackingCtnrStatus:'flex-fill'});
        //call 
        fetch('https://randomuser.me/api/?nat=us', {
            method: 'GET'
          })
          .then((res) => res.json())
          .then((result) => { 
            this.setState({
                taxi_firstname:result.results[0].name.first,
                taxi_lastname:result.results[0].name.last,
                taxi_picture:result.results[0].picture.medium,
                taxi_cell:result.results[0].cell,
            });
        });
        window.scrollTo(0, 0);
    }
    switchInStore(param){
      switch(param) {
          case 'true':
            return <div className="wtb_list_item instore_green"><p><i className="gg-check"></i> <span>In stock</span></p></div>
          case 'false': 
            return <div className="wtb_list_item instore_red"><p><i className="gg-close"></i> <span>Out of stock</span></p></div>
          default:
            return <div className="wtb_list_item instore_orange"><p><i class="gg-ghost-character"></i> <span>Stock unknown</span></p></div>
        }
    }
    renderError(){
        return (       
            <span>Oh snap ! We couldn't find any stores with stock around you, you might try a different location by clicking on the above 'change' link.</span>
        );  
    }
    render() {  
        return (
        
            <div id="wtb_id" className={"h-100 "+this.state.wtbIdCtnrStatus}>
                <Backbutton />
                
                <h1 className="align-self-center text-center pt-3 pl-4 mb-4">Instant Delivery</h1>
                
                <div className="container d-flex flex-column h-100">

                    <div id="id_location_picker" className="row mt-1 id_block">
                        <div className="col-12">
                            We've located you in <b>{this.state.user_location.formatted_address}</b>. <button className="link_btn color-white" onClick={() => this.displayAc()}>Change</button>
                        </div>
                        <div id="acCtnr" className={"col-12 "+this.state.acCtnrStatus}>
                            <div className="col">
                                <LoadScript
                                    googleMapsApiKey="AIzaSyD_bs4JfH85CsnRmBFeKNXO8btEu4c_Ulc"
                                    libraries={libraries}
                                >
                                    <Autocomplete
                                            onLoad={this.onAcLoad}
                                            onPlaceChanged={this.onPlaceChanged}
                                            restrictions={this.state.acConfig}
                                        >
                                        <input
                                        type="text"
                                        placeholder="Enter a location"
                                        className="icInpageInput"
                                        />
                                    </Autocomplete>
                                </LoadScript>
                            </div>
                        </div>
                    </div>

                    <div id="id_store_picker" className={"row id_block "+this.state.storepickerCtnrStatus}>
                        <div className="col-12">
                            {this.state.isLoaded ? (this.state.hasError || this.state.ret_stock_cnt == 0 ? this.renderError() : (
                                <div>
                                    <p>Hey ! We found {this.state.ret_stock_cnt} delivery option(s) near you. Pick the one you would like :</p> 
                                    <div className={"keep_for_reduced "+this.state.selectedStoreCtnr}>
                                    {this.state.locations.map((item,key) => {
                                        if(this.state.retailerInStoreData[item.searchableId] !== undefined){
                                            return <IdStoreDetails key={key}
                                                    item={item}
                                                    index={key}
                                                    isActive={ this.state.selectedStore === key }
                                                    onClick={this.selectStore}
                                                />
                                                

                                        }
                                    })}
                                    </div>
                                </div>
                            )) : (       
                                <div className="d-flex tiny_loader">
                                    <div className="spinner">
                                        <div className="double-bounce1"></div>
                                        <div className="double-bounce2"></div>
                                    </div>
                                    <div className="text">Checking stock information</div>
                                </div>
                            )}  
                        </div>
                    </div>
                    
                    <div id="id_paiement" className={"row id_block "+this.state.paiementCtnrStatus}>
                        <div className="col-12 keep_for_reduced">
                            <div className="row keep_for_reduced">
                                <div className="col-3 keep_for_reduced"><img src={this.state.product_image} className="product_image keep_for_reduced" /></div>
                                <div className="col-9 keep_for_reduced">
                                    <div className="row text-med mb-2"><div class="col-8">{this.state.product_name}</div><div class="col-4 text-right">$350</div></div>
                                    <div className="row text-sml mb-2"><div class="col-8">Delivery fee</div><div class="col-4 text-right">$20.05</div></div>
                                    <div className="row text-sml mb-2"><div class="col-8">Service fee</div><div class="col-4 text-right">$2.13</div></div>
                                    <div className="row text-sml mb-2"><div class="col-8">Tip</div><div class="col-4 text-right">$1.22</div></div>
                                    <div className="row text-sml mb-2"><div class="col-8">Taxes</div><div class="col-4 text-right">$0.79</div></div>
                                    <div className="row text-big mb-2 keep_for_reduced"><div class="col-6 keep_for_reduced">Total</div><div class="col-6 keep_for_reduced text-right">$412</div></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 mt-3 text-center">
                                    <button className="btn-place-order" onClick={() => this.placeOrder()}>Place order</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div id="id_order_tracking" className={"row id_block "+this.state.ordertrackingCtnrStatus}>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-3"><img src={this.state.taxi_picture} className="rounded-circle" /></div>
                                <div className="col-7">
                                    {this.state.taxi_firstname+" "+this.state.taxi_lastname}
                                    <br /><small>☆ 4.7</small>
                                </div>
                                <div className="col-2"><a href={"tel:"+this.state.taxi_cell} className="taxi_phone_ico"><i class="gg-phone"></i></a></div>
                            </div>
                        </div>
                    </div>


                    
                </div>
            </div>
        )                
    }  
}

export default Id;