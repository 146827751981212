import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './Scss/App.scss';



import ScrollToTop from './Components/_ScrollToTop';
import Home from './Home';
import About from './About';

import Organisation from './Organisation';
import Product from './Product';
import Products from './Products';


function App() {
  return (
    <div className="App h-100">
	 <Router>
     
    <ScrollToTop />
         {/*<nav>
          <ul>
            <li>
              <Link to="/product/445936">product/445936</Link>
            </li>
            <li>
              <Link to="/product/100583943">product/100583943</Link>
            </li>
          </ul>
        </nav>

        A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/o/:organisation_id/a/:account_id/p/:product_id/:option?" component={Product} />
          <Route path="/o/:organisation_id/a/:account_id" component={Products} />
          <Route path="/o/:organisation_id" component={Organisation} />
          <Route path="/about" component={About} />
          <Route path="/" component={Home} />
        </Switch>
     
    </Router>
	
   
    </div>
  );
}

export default App;
