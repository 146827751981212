import React from 'react';

import { Link } from 'react-router-dom';

class ReviewsPreview extends React.Component {  
    constructor(props) {
        super(props);
        
        this.state = {
            hasError: false,
            isLoaded: false,
            item: [],
            summary: [],
            distribution: [],
            content:'', 
            product_id: this.props.product_id,
            product_mpn: this.props.product_mpn,
            organisation_id: this.props.organisation_id,            
        };

    }
    componentWillMount() { 
        this.fetchApi(this.state.product_mpn);        
    }
    fetchApi(product_mpn){
        let url =  process.env.REACT_APP_API_URL+'/getProductReview/'+product_mpn+'?countryCode=US&organisation_id='+this.state.organisation_id;
        //process.env.REACT_APP_REVIEW_API_URL+'/5fc613900254c60001a12fcb/consumer?mpn='+product_mpn+'&countryCode=US';    
        fetch(url, {
          method: 'GET',
          headers: { "Content-Type": "application/json", 
          }
        })
        .then((res) => res.json())
        .then((result) => { 
              if(result.status == 200){
                console.log(result);
                this.setState({
                    isLoaded: true,
                    hasError: false,
                    item: result,
                    summary: result.summary,
                    distribution: Object.entries(result.summary.distribution),
                });
              }
              else {
                  this.setState({
                      isLoaded: true,
                      hasError: true
                  });
              }
          },
          (error) => {
              console.log(error.toString());
              this.setState({
                isLoaded: true,
                hasError:true
              });
          }
        )
    }  
    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderReviewPreview()) : (
            <div className="text-muted">Loading reviews</div>
        )
    }
    renderError(){
        return (
            <div className="my-3"></div>
        )
    }
    renderReviewPreview(){
        return (
            <div>
                <div className="h_review_item d-flex mb-2">
                    <div className="h_review_rating justify-content-center align-self-center">
                    {this.state.distribution.map((item,key) => {
                        var k = key+1;
                        return (
                            <span key={"h_star_"+k} id={"h_star_"+k} className={(this.state.summary.averageScore > k ?'fill':'')}>☆</span>
                        )    
                    })}
                    </div>
                    <div className="h_review_rating_score justify-content-center align-self-center">
                        <span>{this.state.summary.averageScore}</span>/5 - <Link to={window.location.pathname+"/reviews"}>Based on {this.state.summary.reviewsAmount} customer reviews</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReviewsPreview;