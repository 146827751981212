import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class BackButton extends Component {
    static contextTypes = {
      router: () => React.PropTypes.object, // replace with PropTypes.object if you use them
    }
  
    render() {
      return (
        
        <button
          className="header_btns backLink btn btn-primary"
          onClick={this.props.history.goBack}>
            <i className="gg-chevron-left"></i>
        </button>
      )
    }
  }
export default withRouter(BackButton);