import React, { Component } from 'react';

import { Link } from 'react-router-dom';
class Home extends React.Component {  

    render() {  
        return (
            <div className="h-100 p-4 pt-5" id="wtb_home">
                <p className="home1">Γεια</p>
                <p className="home2">shopping!</p>
                <p className="home3">Hatch Where to Buy solution has been designed to make all your digital touchpoint shoppable online and offline.</p>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <Link to="/o/5aeaeab446e0fb0001be1ed8/a/8af1794f8fdd1e6299cc64ab4ff113cb" className="account_block">
                                <img src="/cdn/img/acer.png"/>
                            </Link>
                        </div>
                        <div className="col-3">
                            <Link to="/o/5aeaeab746e0fb0001be1ef8/a/ed731eba2b881e234124e48ac5d9e43d" className="account_block">
                                <img src="/cdn/img/msi.png"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }  
}

export default Home;