import React from 'react';

import BackButton from "./_BackButton";

import Loading from './_Loading';


class Reviews extends React.Component {  

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            isLoaded: false,
            item: [],
            summary: [],
            details: [],
            content:'', 
            product_id: this.props.product_id,
            product_mpn: this.props.product_mpn,
            organisation_id: this.props.organisation_id, 
        };
        
        document.title = 'Reviews - '+this.props.product_name;

    }
    componentWillMount() { 
        this.fetchApi(this.state.product_mpn);        
    }
    fetchApi(product_mpn){
        let url =  process.env.REACT_APP_API_URL+'/getProductReview/'+product_mpn+'?countryCode=US&organisation_id='+this.state.organisation_id;
        //process.env.REACT_APP_REVIEW_API_URL+'/5fc613900254c60001a12fcb/consumer?mpn='+product_mpn+'&countryCode=US';    
        fetch(url, {
          method: 'GET',
          headers: { "Content-Type": "application/json", 
          }
        })
        .then((res) => res.json())
        .then((result) => { 
              if(result.status == 200){
                console.log(result);
                this.setState({
                    isLoaded: true,
                    hasError: false,
                    item: result,
                    summary: result.summary,
                    details: result.details,
                });
              }
              else {
                  this.setState({
                      isLoaded: true,
                      hasError: true
                  });
              }
          },
          (error) => {
              console.log(error.toString());
              this.setState({
                isLoaded: true,
                hasError:true
              });
          }
        )
    }  
    render() {  
        return this.state.isLoaded ? (this.state.hasError ? this.renderError() : this.renderReviews()) : (
            <Loading />
        )
    }
    renderError(){
        return (
            <div className="my-3"></div>
        )
    }
    renderReviews(){
        

        return (
            <div className="mt-2 mb-5">
                <BackButton />
                
                <h1 className="align-self-center text-center mt-3 mb-4">Reviews</h1>

                {this.state.details.map((item,key) => {
                    var distribution = Object.entries(item.product.distribution);
                    var d = Object.keys(distribution).sort().reverse();
                    console.log(d);
                    return (
                        <div class="h_review_item h_review_review_cnt hr_m0">
                            
                            <div className="d-flex justify-content-center mb-3">
                                <div className="retailer_score align-self-center">{item.product.averageScore}</div>
                            </div>
                            <p>based on {item.product.reviewsAmount} <b>{item.retailer.name}</b> customer ratings</p>
                                       
                            {d.map((id,kd) => {
                                console.log(id);
                                var vdd =  distribution[id];
                                var k = parseInt(id)+1;
                                var per_dd = Math.round(((vdd[1]*100)/item.product.reviewsAmount));
                                return (
                                    
                                    <div key={'h_detail_'+k} id={'h_detail_'+k} className="h_review_item h_review_review_detail">
                                        <div>{k} star</div>
                                        <div className="meter">
                                            <span style={{width: per_dd+"%"}}></span>
                                        </div>
                                        <div className="per">{per_dd}%</div>
                                    </div>

                                )    
                            })}
                            
                            <hr />
                        </div>
                    )    
                })}
            </div>
        )
    }
}

export default Reviews;