import React from 'react';
import { Link } from 'react-router-dom';

class Organisation extends React.Component {  

    constructor(props) {
        super(props);
     
        this.state = {
            hasError: false,
            isLoaded: false,
            org_data: [],
            organisation_id: this.props.match.params.organisation_id,
        };
    }
    componentWillMount() {
        const currentorganisationId = this.props.match.params.organisation_id
        this.setState({organisation_id: currentorganisationId});
        this.fetchApi(currentorganisationId);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.organisation_id !== this.props.match.params.organisation_id) {
          const neworganisationId = nextProps.match.params.organisation_id
          this.setState({
              isLoaded: false,
              organisation_id: neworganisationId,
          });
          this.fetchApi(neworganisationId);
        }
    }
    fetchApi(organisationId){
    let url =  process.env.REACT_APP_API_URL+'/getOrganisation/'+organisationId;
    fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json", 
        }, //no body > uid_user is stored in token
        //body: JSON.stringify(api_data)
    })
    .then((res) => res.json())
    .then((result) => { 
            if(result){
                console.log(result);
                console.log(result);
                this.setState({
                    isLoaded: true,
                    hasError: false,
                    org_data: result
                });
            }
            else {
                this.setState({
                    isLoaded: true,
                    hasError: true
                });
            }
        },
        (error) => {
            console.log(error.toString());
            this.setState({
                isLoaded: true,
                hasError:true
            });
        }
    )}
    render() {  
        return (
            <div id="wtb_org" className="h-100">
                
                <Link
                className="header_btns backLink"
                to={"/"}>
                    <i class="gg-menu-left"></i>
                </Link>
                
                <div className="container h-100" style={{backgroundImage:"url("+this.state.org_data.bg_img+")"}}>
                    <div className="brand_logo" style={{backgroundImage:"url("+this.state.org_data.logo+")"}}></div>
                    <div className=" d-flex h-100">
                        <div class="justify-content-center align-self-center">
                            <div className="mkt_text p-3">{this.state.org_data.mkt_text}</div>
                            <Link to={"/o/"+this.state.organisation_id+"/a/"+this.state.org_data.account_id} className="btn-blur">Shop now</Link>
                        </div>
                    </div>
                </div>

            </div>
        )
    }  
}

export default Organisation;