import React, { Component } from 'react';

class IdStoreDetails extends React.Component {
    handleClick = () => this.props.onClick(this.props.index)
    
    renderDistance(distance){
        if(distance < 1000) var d = Math.trunc(distance)+' m';
        else var d = ((distance/1000).toFixed(2))+' km';
        return <span>{d}</span>
    }
    render() {
      return (
        <div className={"row wtbl_item mt-1 "+(this.props.isActive?'active':'')} onClick={ this.handleClick }>
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <span className="location_name">{this.props.item.retailer.name}</span>
                        <span className="retailer_name">{this.props.item.name}</span>
                        <span className="location_address">{this.props.item.address.street}, {this.props.item.address.zip} - {this.props.item.locality}</span>
                        <br />
                        <div className="location_distance">Distance : {this.renderDistance(this.props.item.distance)}</div>
                    </div>
                </div>
            </div>
        </div>
      )
    }
  }

  export default IdStoreDetails;