

import React, { Component } from 'react';

class Loading extends React.Component {  

    render() {  
        return (
            <div className="container h-100">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div>
                        <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            </div>
        )
    }  
}

export default Loading;